<template>
    <ul class="tab-header">
        <li v-for="(item,index) in headerList"
            :class="tabClass(item,index)"
            :key="index"
            @click="changeTabPage(item,index)">
            {{item.label}}
        </li>
    </ul>
</template>

<script>
	import {mapState} from 'vuex'

	export default {
		name:"tab-header",
		props:{
			currentTab:Number,
			headerList:Array,
		},
		computed:{
			...mapState([
				'showRankingTypeSelect'
			]),
			tabClass(){
				let {currentTab} = this
				return function(item,index){
					let tabClass = ''
					tabClass += currentTab == index?' current':''
					if(currentTab == index && item.link == 'technician-ranking'){
						tabClass += ' ranking-tab'
					}
					return tabClass
				}
			}
		},
		methods:{
			changeTabPage(item,index){
				let {currentTab,showRankingTypeSelect} = this
				if(currentTab == index && item.link == 'technician-ranking'){
					this.$emit("changeShowType",showRankingTypeSelect)
				}
				if(currentTab == index) return
				this.$emit("changeTabPage",index)
			}
		}
	}
</script>


<style lang="scss" rel="stylesheet/scss" scoped>
@import "~@/assets/style/public.scss";
/*分析和技师头部*/
.tab-header{
    display:flex;
    padding:20px 32px;
    width:100%;
    background:$theme-main-color;

    li{
        flex:1;
        text-align:center;
        padding:16px 0;
        line-height:1;
        font-size:26px;
        color:#FFFFFF;
        border:#FFFFFF 2px solid;
        height:58px;
    }
    
    li:last-child{
        border-radius:0 10px 10px 0;
        border-left:0;
    }

    li:first-child{
        border-radius:10px 0 0 10px;
        border-right:0;
    }
    
    .current{
        background:#FFFFFF;
        color:$theme-main-color;
    }
    
    .ranking-tab{
        position:relative;
        &:after{
            content:'';
            position:relative;
            top:24px;
            left:2px;
            width: 0;
            height: 0;
            border-width: 14px 10px 0 ;
            border-style: solid;
            border-color:$high-light-color transparent transparent ;
        }
    }
}

</style>
